
export type T_PARTNER_LIST_ITEM = {
  name:string
  code:string
}
export const PARTNERS_LIST:T_PARTNER_LIST_ITEM[] = [
  {name: "Recovery One",              code: "ro"},
  {name: "Vori",                   code: "vori@care"},
  {name: "DocHQ",                     code: "dochq"},
  {name: "Maccabi",                   code: "maccabi@care"},
  {name: "IDF",                   code: "idf@care"},
  {name: "Alyn",                   code: "alyn@care"},
  {name: "BeyondTheClinic",                   code: "BeyondTheClinic"},
  {name:"Princeton Longevity Center",code:"princeton-longevity-center@care"},

  

  {name: "EXI" ,                     code: "EXI"},
  {name: "Lupin" ,                     code: "Lupin"},
  {name: "Precision Health",          code: "PrecisionHealth"},
  {name: "Clever Health",             code: "CleverHealth"},
  {name: "Avani Labs",               code: "Avani Labs"},
  {name:  "HSS",                     code: "HSS"},
  {name:  "Toro Science",            code: "Toro Science"},
  {name:  "Somatic Health",           code: "SomaticHealth"},
  {name:  "Livara",                  code: "Livara"},

]

export const OTHER_PARTNERS_LIST:T_PARTNER_LIST_ITEM[] = [
  {name: "vori (kemtapi)",                      code: "vori"},
  {name: "kemtai",                    code: "kemtai"},
  {name: "Gympass",                   code: "gympass"},
  {name: "180MSK",                    code: "msk180"},
  {name: "betr",                      code: "betr"},

  {name: "upswing",                   code: "upswing-health"},
  {name: "icura",                     code: "icura"},
  {name: "moveo-start",               code: "moveo-start"},
  {name: "upmc-sports-med",           code: "upmc-sports-med"},
  {name: "latus-health",              code: "latus-health"},

  {name: "doron",                     code: "doron"},
  {name: "kemtapi (old Vori+Doron)",  code: "kemtapi"},
  {name: "development",               code: "development"},
  {name: "Recovery One Acceptance",   code: "ro-acceptance"},
  {name: "Dan's Clinic",              code: "dan-cohen"},
  //{name: "Care",              code: "care"},
  {name: "Care-Manage",              code: "care-manage"},
]


export const CARE_LIST:T_PARTNER_LIST_ITEM[] = [
    {name: "sheba",                   code: "sheba@care"},
    {name: "nypq",                      code: "nypq@care"},
    {name: "physio",                    code: "physio@care"},

    {name: "kevin-jiang",              code: "kevin-jiang@care"},
    {name: "the-brain-gym",                    code: "the-brain-gym@care"},
  ]

  
  
  
  
  
 